.side-nav {
  border-radius: 8px;
  background: #101117;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 760px;
  max-width: 290px;
  .profile-pic-cont {
    display: flex;
    margin-top: 32px;
    border-bottom: 1px solid #40435B;
    .info-inner {
      overflow: hidden;
      span {
        color: #C1FC49;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      h2 {
        margin-bottom: 0;
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .ant-avatar {
      margin-bottom: 16px;
      margin-right: 10px;
      flex-shrink: 0; 
    }
    .not_connected {
      top: 0;
    }
  }
  .ant-menu {
    background: transparent;
    margin-top: 32px;
    &-item {
      padding: 23px;
      color: #FFFFFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      &-active {
        background-color: #20222E !important;
        color: #FFFFFF !important;
        .ant-menu-item-icon {
          color: #C1FC49;
        }
      }
      &-selected {
        background-color: #20222E;
        color: #C1FC49;
      }
      &-disabled {
        color: #575757 !important;
      }
    }
  }
  .bar-cont {
    position: relative;
    margin-top: auto;
    .upgrade {
      position: absolute;
      right: 35px;
      top: -12px;
      color: #C1FC49;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 800;
    }
    .ant-progress {
      .ant-progress-text {
        display: flex;
        margin-inline-start: 0;
        position: absolute;
        top: -10px;
        color: #EDF2F6;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 800;
      }
    }
  }
  .info-wrapper {
    position: absolute;
    bottom: 45px;
    width: 85%;
    .ant-list {
      .ant-btn {
        padding: inherit;
        height: 52px;
        width: 80%;
        background-color: #20222E;
        border: none;
        color: #FFFFFF;
        .anticon-logout {
          color: #ff4d4f;
        }
        .anticon-bell {
          color: #FFFFFF;
        }
        sup {
          width: 4px;
          height: 4px;
          min-width: 4px;
          box-shadow: none;
        }
      }
    }
    .poweredby {
      margin-top: 10px;
      p {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
  .ant-switch {
    border: 1px solid #c1fc49;
    height: 17px !important;
		&-handle {
			&::before {
				background-color: #c1fc49;
			}
		}
		&-checked {
			background-color: #c1fc49;
      .ant-switch-handle {
        &::before {
				  background-color: #20222e;
			  }
      }
		}
		&-inner {
			&-checked {
				color: #20222e !important;
				font-weight: bold;
			}
			&-unchecked {
				font-weight: bold;
			}
		}
	}
}