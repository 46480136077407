// Default style of modal
.ant-modal {
	&-content {
		background-color: #303244 !important;
		.ant-list-pagination {
			.ant-pagination {
        &-item {
          background-color: transparent !important;
        }
        &-item-active {
					background-color: #c1fc49 !important;
					border: none;
				}
			}
		}
		.ant-modal-close {
			margin-top: 7px;
			color: #ffffff;
			&-x {
				font-size: 22px;
			}
		}
		.ant-modal-header {
			background-color: transparent;
			.ant-modal-title {
				color: #ffffff;
				font-family: Poppins;
				font-size: 24px;
				font-weight: 300;
				line-height: 36px;
			}
		}
		.ant-modal-footer {
			.ant-btn-default {
				border: 1px solid #66697c;
				background-color: transparent;
				color: #ffffff;
				border-radius: 32px;
			}
			.ant-btn-primary {
				border-radius: 32px;
				background: #c1fc49;
				color: #20222e;
				font-weight: 600;
				&:disabled {
					background: #40435b;
					color: #66697c;
				}
			}
		}
	}
}

.connect-wallet {
	.ant-modal {
		&-content {
			background: #20222e !important;
			.ant-modal-close {
				margin-top: 7px;
				color: #ffffff;
			}
			.ant-input-affix-wrapper {
				border-radius: 32px;
				border: 1px solid #40435b;
				background: #303244;
				padding: 7px;
				.ant-input {
					background-color: transparent;
					color: #FFFFFF;
					&::placeholder {
						color: #FFFFFF;
					}
				}
			}
			.ant-input-affix-wrapper-focused {
				border-color: #c1fc49;
			}
			.ant-list {
				margin-top: 32px;
			}
			.ant-card {
				border-radius: 8px;
				background: #303244;
				border: none;
				height: 80px;
				width: 30%;
				margin-top: 20px;
				.ant-image {
					position: relative;
					display: block;
					margin: auto;
				}
				p {
					text-align: center;
				}
				&-body {
					padding: 12px;
					p {
						color: #ffffff;
						font-family: Poppins;
						font-size: 14px;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
			.connected {
				h1 {
					color: #fff;
					font-family: Poppins;
					font-size: 24px;
					font-weight: 600;
					line-height: 36px;
					margin-top: 24px;
				}
				p {
					color: #ffffff;
					font-family: Poppins;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					margin-bottom: 10px;
				}
				.actions {
					display: flex;
					justify-content: left;
					align-items: center;
					margin-top: 32px;
					.ant-btn-default {
						border: 1px solid #66697c;
						background-color: transparent;
						color: #ffffff;
						border-radius: 32px;
						margin-right: 15px;
					}
					.ant-btn-primary {
						border-radius: 32px;
						background: #c1fc49;
						color: #20222e;
						&:disabled {
							background: #40435b;
							color: #66697c;
						}
					}
				}
			}
		}
	}
}
.preview-nft {
	.ant-modal {
		&-content {
			padding: 24px;
			.ant-modal-header {
				margin-bottom: 24px;
			}
			.ant-modal-body {
				.right-wrapper {
					padding-top: 16px;
					.title-wrap {
						h1 {
							color: #ffffff;
							font-family: Poppins;
							font-size: 20px;
							font-weight: 700;
							line-height: 30px;
							margin-bottom: 2px;
						}
						p {
							color: #c1fc49;
							font-family: Poppins;
							font-size: 14px;
							font-weight: 400;
							line-height: 17.5px;
						}
					}
					.ant-tabs {
						&-nav {
							&::before {
								border-bottom: none;
							}
							.ant-tabs-nav-wrap {
								.ant-tabs-nav-list {
									border-bottom: 1px solid #40435b;
									.ant-tabs-tab {
										padding: 12px;
										&-btn {
											color: #ffffff;
											font-family: Poppins;
											font-size: 16px;
											font-weight: 400;
											line-height: 24px; /* 24px */
											.anticon {
												margin-right: 4px;
											}
											.ant-tabs-tab-icon {
												margin-inline-end: 6px;
											}
										}
									}
									.ant-tabs-tab-active {
										.ant-tabs-tab-btn {
											color: #c1fc49;
										}
									}
									.ant-tabs-ink-bar {
										background: #c1fc49;
									}
								}
							}
						}
						&-content {
							.ant-list {
								border: none;
								&-items {	
									.ant-list-item {
										border-block-end: 1px solid #40435b;
										padding: 16px 1px;
										.item-wrapper {
											width: 100%;
											display: flex;
											justify-content: space-between;
											align-items: center;
											overflow: hidden;
											h3 {
												color: #ffffff;
												font-family: Poppins;
												font-size: 14px;
												font-weight: 700;
												line-height: 21px;
												margin-bottom: 0;
												text-wrap: nowrap;
											}
											span {
												color: #ffffff;
												font-family: Poppins;
												font-size: 14px;
												font-style: normal;
												font-weight: 400;
												line-height: 21px;
												overflow: hidden;
												text-wrap: nowrap;
											}
											.file-name {
												margin-left: 40px;
											}
										}
										&-meta {
											&-avatar {
												.ant-avatar {
													background: #181a26;
													img {
														object-fit: scale-down;
														width: 15px;
														height: 17px;
														display: inline-block;
                            							vertical-align: sub;
													}
												}
											}
										}
										.ant-list-item-action {
											.anticon-eye {
												width: 18px;
												height: 16px;
												color: #c1fc49;
											}
										}
									}
									.details-list {
										border-block-end: none;
										border-radius: 8px;
										border: 1px solid #40435b;
										margin-bottom: 8px;
										padding: 16px;
									}
								}
							}
							.details {
								.ant-list {
									.ant-list-items {
										height: auto;
										overflow: scroll;
									}
								}
								.add-asset-list {
									.ant-list-items {
										height: 200px;
										overflow: scroll;
									}
                				}
							}
							.nft-list-wrap {
								.nft-list {
									border: none;
									background: #1c1d28;
									overflow: auto;
									max-height: 248px;
									.ant-list-items {
                    					padding: 10px;
										.ant-list-item {
											border-block-end: 1px solid #40435B !important;
											padding: 10px !important;
											background: #40435B;
											margin-bottom: 10px;
											border-radius: 10px;
											.item-wrapper {
												width: 100%;
												display: flex;
												justify-content: space-between;
												align-items: center;
												overflow: hidden;
											h3 {
												color: #FFFFFF !important;
												font-family: Poppins;
												font-size: 14px;
												font-weight: 700;
												line-height: 21px;
												margin-bottom: 0;
												min-width: 70px;
												text-wrap: nowrap;
											}
											span {
												color: #FFFFFF;
												font-family: Poppins;
												font-size: 14px;
												font-style: normal;
												font-weight: 400;
												line-height: 21px;
												overflow: hidden;
												white-space: nowrap;
												text-overflow: ellipsis;
											}
											.file-name {
											  margin-left: 40px;
											}
										}
										&-meta {
											&-avatar {
												.ant-avatar {
													background: #181a26;
													img {
														object-fit: contain;
														width: 16px;
														height: 30px;
														margin: auto;
													}
												}
											}
											&-content {
												.ant-list-item-meta-title {
												color: #FFFFFF !important;
												}
											}
										}
										.ant-list-item-action {
											.anticon-eye {
											width: 18px;
											height: 16px;
											color: #C1FC49;
											}
										}
											.action-wrapper {
												display: flex;
												justify-content: center;
												align-items: baseline;
												.contributors {
													color: #C1FC49;
													font-family: Poppins;
													margin-top: 6px;
													display: flex;
													justify-content: space-between;
													padding-right: 30px;
													span {
														font-size: 12px;
														font-weight: 400;
														line-height: 18px;
													}
												}
												.date {
													color: #FFFFFF !important;
												}
											}
										}
										.details-list {
											border-block-end: none;
											border-radius: 8px;
											border: 1px solid #40435B;
											margin-bottom: 8px;
											padding: 16px;
										}
									}
									.ant-list-empty-text {
										background-color: #303244;
									}
								}
							}
						}
					}
				}
				.cover-container {
					height: 100%;
					background: #1c1d28;
					border-radius: 8px;
					display: flex;
					.ant-image {
						display: table;
						margin: auto;
						.cover-image {
							border-radius: 8px;
							// min-height: 250px;
							max-height: 486px;
						}
					}
        }
			}
			.ant-modal-footer {
				.ant-btn {
					svg {
						vertical-align: baseline;
					}
				}
			}
		}
	}
}
.collection-preview {
	.ant-modal {
		&-content {
			padding: 24px;
			.ant-modal-header {
				margin-bottom: 24px;
			}
			.ant-modal-body {
				.right-wrapper {
					padding-top: 16px;
					.title-wrap {
						h1 {
							color: #ffffff;
							font-family: Poppins;
							font-size: 20px;
							font-weight: 700;
							line-height: 30px;
							margin-bottom: 2px;
						}
						p {
							color: #c1fc49;
							font-family: Poppins;
							font-size: 14px;
							font-weight: 400;
							line-height: 17.5px;
						}
					}
					.ant-tabs {
						&-nav {
							&::before {
								border-bottom: none;
							}
							.ant-tabs-nav-wrap {
								.ant-tabs-nav-list {
									border-bottom: 1px solid #40435b;
									.ant-tabs-tab {
										padding: 12px;
										&-btn {
											color: #ffffff;
											font-family: Poppins;
											font-size: 16px;
											font-weight: 400;
											line-height: 24px; /* 24px */
											.anticon {
												margin-right: 4px;
											}
											.ant-tabs-tab-icon {
												margin-inline-end: 6px;
											}
										}
									}
									.ant-tabs-tab-active {
										.ant-tabs-tab-btn {
											color: #c1fc49;
										}
									}
									.ant-tabs-ink-bar {
										background: #c1fc49;
									}
								}
							}
						}
						&-content {
							.ant-list {
								border: none;
								&-items {	
									.ant-list-item {
										border-block-end: 1px solid #40435b;
										padding: 16px 1px;
										.item-wrapper {
											width: 100%;
											display: flex;
											justify-content: space-between;
											align-items: center;
											overflow: hidden;
											h3 {
												color: #ffffff;
												font-family: Poppins;
												font-size: 14px;
												font-weight: 700;
												line-height: 21px;
												margin-bottom: 0;
												text-wrap: nowrap;
											}
											span {
												color: #ffffff;
												font-family: Poppins;
												font-size: 14px;
												font-style: normal;
												font-weight: 400;
												line-height: 21px;
												overflow: hidden;
												white-space: nowrap !important;
												text-overflow: ellipsis !important;
											}
											.file-name {
												margin-left: 40px;
											}
										}
										&-meta {
											&-avatar {
												.ant-avatar {
													background: #181a26;
													img {
														object-fit: scale-down;
														width: 15px;
														height: 17px;
														display: inline-block;
                            							vertical-align: sub;
													}
												}
											}
										}
										.ant-list-item-action {
											.anticon-eye {
												width: 18px;
												height: 16px;
												color: #c1fc49;
											}
										}
									}
									.details-list {
										border-block-end: none;
										border-radius: 8px;
										border: 1px solid #40435b;
										margin-bottom: 8px;
										padding: 16px;
									}
								}
							}
							.details {
								.ant-list {
									.ant-list-items {
										height: auto;
										overflow: scroll;
									}
								}
								.add-asset-list {
									.ant-list-items {
										height: 200px;
										overflow: scroll;
									}
                				}
							}
							.nft-list-wrap {
								.nft-list {
									border: none;
									background: #1c1d28;
									overflow: auto;
									max-height: 248px;
									.ant-list-items {
                    padding: 10px;
										.ant-list-item {
											border-block-end: 1px solid #40435B !important;
											padding: 10px !important;
											background: #40435B;
											margin-bottom: 10px;
											border-radius: 10px;
											.item-wrapper {
												width: 100%;
												display: flex;
												justify-content: space-between;
												align-items: center;
												overflow: hidden;
											h3 {
												color: #FFFFFF !important;
												font-family: Poppins;
												font-size: 14px;
												font-weight: 700;
												line-height: 21px;
												margin-bottom: 0;
												min-width: 70px;
												text-wrap: nowrap;
											}
											span {
												color: #FFFFFF;
												font-family: Poppins;
												font-size: 14px;
												font-style: normal;
												font-weight: 400;
												line-height: 21px;
												overflow: hidden;
												white-space: nowrap;
												text-overflow: ellipsis;
											}
											.file-name {
											  margin-left: 40px;
											}
										  }
                      &-meta {
                        &-avatar {
                          .ant-avatar {
                            background: #181a26;
                            img {
                              object-fit: contain;
                              width: 16px;
                              height: 30px;
                              margin: auto;
                            }
                          }
                        }
                        &-content {
                          .ant-list-item-meta-title {
                          color: #FFFFFF !important;
                          }
                        }
                        .name-wrapper {
                          .owner {
                            color: #c1fc49;
                          }
                        }
                      }
                      .ant-list-item-action {
                        .anticon-eye {
                        width: 18px;
                        height: 16px;
                        color: #C1FC49;
                        }
                      }
											.action-wrapper {
												display: flex;
												justify-content: center;
												align-items: baseline;
												.contributors {
													color: #C1FC49;
													font-family: Poppins;
													margin-top: 6px;
													display: flex;
													justify-content: space-between;
													padding-right: 30px;
													span {
														font-size: 12px;
														font-weight: 400;
														line-height: 18px;
													}
												}
												.date {
													color: #FFFFFF !important;
												}
											}
										}
										.details-list {
											border-block-end: none;
											border-radius: 8px;
											border: 1px solid #40435B;
											margin-bottom: 8px;
											padding: 16px;
										}
									}
									.ant-list-empty-text {
										background-color: #303244;
									}
								}
							}
						}
					}
				}
				.cover-container {
					height: 100%;
					background: #1c1d28;
					border-radius: 8px;
					display: flex;
					.ant-image {
						display: table;
						margin: auto;
						.cover-image {
							border-radius: 8px;
							// min-height: 250px;
							max-height: 486px;
						}
					}
        }
			}
			.ant-modal-footer {
				.ant-btn {
					svg {
						vertical-align: baseline;
					}
				}
			}
		}
	}

}
.upload-assets {
	.ant-modal {
		border-radius: 8px;
		&-content {
			background: #20222e !important;
			.ant-modal-body {
				.ant-menu {
					background-color: transparent;
					border-inline-end: 1px solid #40435b;
					padding-bottom: 260px;
					&-item {
						padding: 16px !important;
						color: #ffffff;
						.ant-menu-title-content {
							color: #ffffff;
							font-family: Poppins;
							font-size: 14px;
							font-weight: 400;
							line-height: 21px;
						}
						&-active {
							background-color: #20222e !important;
							color: #ffffff !important;
							.ant-menu-item-icon {
								color: #c1fc49;
							}
						}
						&-selected {
							background-color: #20222e;
							color: #c1fc49;
						}
					}
				}
				.upload-data {
					display: flex;
					justify-content: center;
					position: relative;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					.ant-btn {
						background: transparent;
						color: #c1fc49;
						border: 1px solid #c1fc49;
					}
				}
				.upload-assets {
					width: 100%;

					label {
						color: #ffffff !important;
						font-family: Poppins;
						font-size: 14px !important;
						font-style: normal;
						font-weight: 400;
					}
					.ant-input-number-group-addon {
						border: none !important;
						color: unset;
					}
					.ant-input {
						border-radius: 32px;
						border: 1px solid #40435b;
						background: transparent;
						color: #FFFFFF;
						height: 45px;
						&::placeholder {
							color: #FFFFFF;
						}
						&:focus {
							border-color: #c1fc49;
						}
						&[aria-required="true"] {
							background: #303244;
						}
					}
					.ant-input-number,
					.ant-input-number-wrapper {
						border-radius: 32px;
						border: 1px solid #40435b;
						background: #303244 !important;
						color: #FFFFFF;
						height: 45px;
						&:focus {
							border-color: #c1fc49;
						}
						&[aria-required="true"] {
							background: #303244;
						}
						&-input {
							color: #FFFFFF;
							// margin-top: 7px;
							&::placeholder {
								color: #FFFFFF !important;
							}
						}
					}
					.ant-select {
						background: transparent;
						height: 45px;
						border-radius: 32px;
						.ant-select-selector {
							background: transparent;
							border-radius: 32px;
							border: 1px solid #40435b;
							color: #FFFFFF;
						}
						&::placeholder {
							color: #FFFFFF;
						}
						&:focus {
							border-color: #c1fc49;
						}
						&[aria-required="true"] {
							background: #303244;
						}
						.ant-select-arrow {
							color: #FFFFFF;
						}
					}
					.ant-select-in-form-item {
						// background: #303244 !important;
						color: #FFFFFF !important;
					}
					.btn-wrapper {
						display: flex;
						justify-content: flex-start;
						align-items: center;
					}
					.upload-wrap {
						display: flex;
						align-items: center;
						position: relative;
						.ant-btn-primary {
							border-radius: 10px;
							background: #101117;
							color: #c1fc49;
							position: absolute;
							right: 0px;
							height: 45px;
							border-radius: 0px 10px 10px 0px;
							box-shadow: none;
						}
					}
					.ant-upload-list {
						position: absolute;
						top: 4px;
						left: 40%;
					}
					.ant-upload-wrapper {
						.ant-upload {
							// display: block;
							p {
								color: #ffffff !important;
							}
							.anticon-inbox {
								color: #c1fc49;
							}
						}
					}
					.drag-upload {
						.ant-upload-list {
							position: inherit;
							display: none;
						}
					}
				}
			}
		}
	}
}
.add-contributor {
	label {
		color: #ffffff !important;
		font-family: Poppins;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400;
	}
	.ant-input {
		border-radius: 32px;
		border: 1px solid #40435b;
		background: transparent;
		color: #FFFFFF;
		height: 45px;
		&::placeholder {
			color: #FFFFFF;
		}
		&:focus {
			border-color: #c1fc49;
		}
		&[aria-required="true"] {
			background: #303244;
		}
	}
	.ant-input-affix-wrapper {
		border-radius: 32px;
		border: 1px solid #40435b !important;
		border-color: #40435b !important;
		background: #40435b !important;
		color: #FFFFFF;
		// height: 45px;
		&::placeholder {
			color: #FFFFFF;
		}
		&:focus {
			border-color: #c1fc49;
		}
		&[aria-required="true"] {
			background: #303244;
		}
		.ant-input-show-count-suffix {
			color: #c1fc49;
		}
	}
	.ant-select {
		background: transparent;
		height: 45px;
		border-radius: 32px;
		.ant-select-selector {
			background: transparent;
			border-radius: 32px;
			border: 1px solid #40435b;
			color: #FFFFFF;
		}
		&::placeholder {
			color: #FFFFFF;
		}
		&:focus {
			border-color: #c1fc49;
		}
		&[aria-required="true"] {
			background: #303244;
		}
		.ant-select-arrow {
			color: #FFFFFF;
		}
	}
	.ant-select-in-form-item {
		// background: #303244 !important;
		color: #FFFFFF !important;
	}
	.btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.ant-btn-default {
			border: 1px solid #66697c;
			background-color: transparent;
			color: #ffffff;
			border-radius: 32px;
		}
		.ant-btn-primary {
			border-radius: 32px;
			background: #c1fc49;
			color: #20222e;
			&:disabled {
				background: #40435b;
				color: #66697c;
			}
		}
	}
	.upload-wrap {
		display: flex;
		align-items: center;
		position: relative;
		.ant-btn-primary {
			border-radius: 10px;
			background: #101117;
			color: #c1fc49;
			position: absolute;
			right: 0px;
			height: 45px;
			border-radius: 0px 10px 10px 0px;
			box-shadow: none;
			width: auto;
		}
	}
	.check-wrapper {
		position: relative;
	}
	.ant-checkbox-wrapper {
		// position: absolute;
		// right: 0;
		// top: 0;
		label {
			span {
				color: #ffffff !important;
			}
		}
		.ant-checkbox-inner {
			background-color: #66697c;
			border: none;
		}
		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #c1fc49;
			border-color: #c1fc49;
			&::after {
				border-color: #303244;
			}
			&:hover {
				background-color: #c1fc49;
			}
		}
	}
	.ant-switch {
		width: 80px;
		&-handle {
			&::before {
				background-color: #c1fc49;
			}
		}
		&-checked {
			background-color: #c1fc49;
		}
		&-inner {
			&-checked {
				color: #20222e !important;
				font-weight: bold;
			}
			&-unchecked {
				font-weight: bold;
			}
		}
	}
	.ant-switch-checked {
		&-handle {
			&::before {
				background-color: #20222e !important;
			}
		}
		&:hover {
			opacity: 0.9;
			background: #c1fc49 !important;
		}
	}
	.ant-modal-body {
		margin-top: 25px;
	}
	.allow-sale {
		margin-bottom: 0;
		display: block;
		height: 0;
	}
}
.ant-select-dropdown {
	background: #303244 !important;
	color: #FFFFFF !important;
	.rc-virtual-list {
		background: #303244 !important;
		color: #FFFFFF !important;
		border: none;
		padding: 0;
	}
}
.ant-select .ant-select-selection-placeholder {
	color: #FFFFFF !important;
}
.upload-profile {
	.ant-modal {
		&-content {
			.ant-modal-close {
				margin-top: 7px;
				color: #ffffff;
			}
			.ant-input-affix-wrapper {
				border-radius: 32px;
				border: 1px solid #40435b;
				background: #303244;
				padding: 7px;
				.ant-input {
					background-color: transparent;
					color: #FFFFFF;
					&::placeholder {
						color: #FFFFFF;
					}
				}
			}
			.ant-input-affix-wrapper-focused {
				border-color: #c1fc49;
			}
			.ant-modal-body {
				margin-top: 48px;
				.ant-upload-wrapper {
					.ant-upload {
						border-radius: 8px;
						border: 2px dashed #303244;
						background: #303244;
						&-text {
							color: #ffffff;
							text-align: center;
							font-family: Poppins;
							font-size: 14px;
							font-weight: 400;
							line-height: 21px;
						}
						&-drag-icon {
							.anticon {
								color: #ffffff;
							}
						}
						&-hint {
							color: #ffffff;
							text-align: center;
							font-family: Poppins;
							font-size: 11px;
							font-weight: 600;
							line-height: 16.5px;
						}
					}
					.ant-upload-list {
						.ant-upload-list-item-container {
							border-radius: 8px;
							background: #303244;
							margin-top: 16px;
							padding: 16px;
							.ant-upload-list-item {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
}
.action-complete {
	.inner-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// padding-top: 70px;
		h1 {
			color: #ffffff;
			text-align: center;
			font-family: Poppins;
			font-size: 32px;
			font-weight: 800;
			line-height: 48px;
		}
		P {
			color: #ffffff;
			text-align: center;
			font-family: Poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 30px;
		}
		.anticon {
			color: #c1fc49;
			margin-bottom: 20px;
			.anticon-check-circle {
				width: 64px;
				height: 64px;
			}
		}
	}
}
.asset-preview-modal {
	.ant-modal {
		&-content {
			.inner-wrapper {
				video {
					max-height: 500px;
				}
				img {
					max-height: 500px;
				}
			}
			.ant-modal-title {
				margin-top: 20px;
			}
		}
	}
}
.log-out {
	.inner-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// padding-top: 70px;
		h1 {
			color: #ffffff;
			text-align: center;
			font-family: Poppins;
			font-size: 32px;
			font-weight: 800;
			line-height: 48px;
		}
		P {
			color: #ffffff;
			text-align: center;
			font-family: Poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 30px;
		}
		.anticon {
			color: #c1fc49;
			margin-bottom: 20px;
			.anticon-check-circle {
				width: 64px;
				height: 64px;
			}
		}
	}
	.ant-modal-content {
		padding: 40px 40px 20px 40px;
		.ant-modal-footer {
			text-align: center;
			.ant-btn {
				width: 100px;
			}
		}
	}
}
.payment-modal {
	.kyc-step {
      border-radius: 10px;
      margin: auto;
      .ant-card {
        background: transparent;
		padding: 15px 0px;
        .inner-wrap {
          label {
            color: #FFFFFF !important;
            font-family: Poppins;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
          }
          .ant-form {
            .ant-select {
              background: #242634;
              height: 45px;
              border-radius: 32px;
              width: 100%;
              margin: auto;
              display: block;
              .ant-select-selector {
                background: transparent;
                border-radius: 32px;
                border: 1px solid #40435B;
                color: #FFFFFF;
              }
              &::placeholder {
                color: #FFFFFF;
              }
              &:focus {
                border-color: #C1FC49;
              }
              &[aria-required="true"] {
                background: #303244;
              }
              .ant-select-arrow {
                color: #FFFFFF;
              }
            }
          }
		  .ant-checkbox {
            align-self: flex-start;
            margin-top: 3px 
			}
          .meta-btn {
			margin-top: 10px;
			margin-bottom: 10px;
			width: 100%;
			border-radius: 8px;
			height: 48px;
			color: #FFFFFF;
			background: linear-gradient(to bottom, #46485f 0%, #292b3a 50%, #3b3e57 100%);
			.ant-image {
				margin-right: 2px;
			}
        }
		  .active-meta {
			  border: 1px solid #c1fc49;
			  color: #c1fc49;
               box-shadow: 0 5px 15px rgba(193, 252, 73, .4);
		  }
          .steps-cont{
            background-color: #303244;
            width: 100%;
            border-radius: 8px;
            padding: 0 15px;
            margin-bottom: 25px;
            .ant-timeline {
                padding-top: 30px;
                &-item {
                    color: #ffffff;
					font-size: 16px;
                    .anticon-export {
                        font-size: 17px;
                        margin-right: 5px;
                    }
                    .info-cont {
                        background: #242634;
                        width: 400px;
                        padding: 5px 10px;
                        border-radius: 8px;
                        display: flex;
                        justify-content: space-between;
                        color: #ffffff;
                        margin-top: 5px;
                    }
                    &:last-child {
						padding-bottom: 0;
					}
                    &-tail {
                        border-inline-start:2px solid #242634;
                    }
                    &-head {
                        background-color: #242634;
                    }
                }
            }
          }
          h2 {
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 15px;
            font-weight: 600;
            margin-top: 25px;
          }
          .link {
            color: #C1FC49;
            cursor: pointer;
            font-weight: 700;
          }
        }
      }
	}
}
.buy-credits-modal {
	.pay {
      border-radius: 10px;
      margin: auto;
      .ant-card {
		// width: 60%;
        background: transparent;
        // border: 1px solid #889b61;
		padding: 15px 0px;
		.ant-card-body {
			padding: 0;
		}
        .inner-wrap {
          label {
            color: #FFFFFF !important;
            font-family: Poppins;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400;
          }
          .ant-form {
            .ant-select {
              background: #242634 !important;
              height: 45px;
              border-radius: 32px;
              width: 100%;
              margin: auto;
              display: block;
              .ant-select-selector {
                background: transparent;
                border-radius: 32px;
                border: none;
                color: #FFFFFF;
				.ant-select-selection-item {
					color: #FFFFFF;
				}
              }
              &::placeholder {
                color: #FFFFFF;
              }
              &:focus {
                border-color: #C1FC49;
              }
              &[aria-required="true"] {
                background: #303244;
              }
              .ant-select-arrow {
                color: #FFFFFF;
              }
            }
			.ant-input-number {
				background: #242634 !important;
				border: none;
			}
          }
		  .ant-checkbox {
            align-self: flex-start;
            margin-top: 3px 
		    }
          .meta-btn {
            margin-top: 10px;
			margin-bottom: 10px;
            width: 100%;
            border-radius: 8px;
            height: 48px;
			color: #FFFFFF;
			background: linear-gradient(to bottom, #46485f 0%, #292b3a 50%, #3b3e57 100%);
			.ant-image {
				margin-right: 2px;
			}
          }
		  .active-meta {
			  border: 1px solid #c1fc49;
			  color: #c1fc49;
               box-shadow: 0 5px 15px rgba(193, 252, 73, .4);
		  }
          .steps-cont{
            background-color: #303244;
            width: 100%;
            border-radius: 8px;
            padding: 0 15px;
            margin-bottom: 25px;
            .ant-timeline {
                padding-top: 30px;
                &-item {
                    color: #ffffff;
					font-size: 16px;
                    .anticon-export {
                        font-size: 17px;
                        margin-right: 5px;
                    }
                    .info-cont {
                        background: #242634;
                        width: 400px;
                        padding: 5px 10px;
                        border-radius: 8px;
                        display: flex;
                        justify-content: space-between;
                        color: #ffffff;
                        margin-top: 5px;
                    }
                    &:last-child {
						padding-bottom: 0;
					}
                    &-tail {
                        border-inline-start:2px solid #242634;
                    }
                    &-head {
                        background-color: #242634;
                    }
                }
            }
          }
          h2 {
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 15px;
            font-weight: 600;
            margin-top: 25px;
          }
          .link {
            color: #C1FC49;
            cursor: pointer;
            font-weight: 700;
          }
        }
		.ant-btn-disabled {
			color: #575757 !important;
		}
		.ant-btn-primary {
			border-radius: 32px;
			background: #c1fc49;
			color: #20222e;
			font-weight: 600;
		}
      }
	}
}
.select-supernft {
	.inner-wrapper {
		h1 {
			color: #ffffff;
			text-align: center;
			font-family: Poppins;
			font-size: 32px;
			font-weight: 800;
			line-height: 48px;
		}
		.ant-list {
			max-height: 550px;
			overflow: auto;
			.ant-list-items {
				cursor: pointer;
				.active {
				border-radius: 8px;
				border: 1px solid #C1FC49;
				}
				.ant-list-item {
				border-radius: 8px;
				background: #20222E;
				margin-bottom: 10px;
				padding: 16px;
				.ant-list-item-meta {
					align-items: flex-start;
					&-title {
					color: #FFFFFF;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 24px;
					}
					&-description {
					color: #FFFFFF;
					font-family: Poppins;
					font-size: 12px;
					font-weight: 400;
					line-height: 5px;
					}
					.name-wrapper {
						display: flex;
						align-items: center;
						width: 450px;
						justify-content: space-between;
					.name {
						font-family: Poppins;
						font-size: 14px;
						font-weight: 600;
						text-align: left;
						color: #FFFFFF !important;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 220px;
					}
					.owner {
						font-family: Poppins;
						font-size: 14px;
						font-weight: 400;
						text-align: left;
						color: #C1FC49 !important;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 150px;
					}
					}
				}
				.date {
					font-family: Poppins;
					font-size: 14px;
					font-weight: 400;
					line-height: 14px;
					text-align: left;
					color: #ECEDEF !important;
				}
				}
			}
    	}
		.action-wrapper {
			display: flex;
			justify-content: center;
			align-items: baseline;
			.contributors {
				color: #C1FC49;
				font-family: Poppins;
				margin-top: 6px;
				display: flex;
				justify-content: space-between;
				padding-right: 30px;
				span {
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				}
      		}
		}
	}
}

.contributor-list {
  .ant-list-items {
    .ant-list-item {
      padding: 5px 0;
      .ant-list-item-meta-title {
        font-size: 12px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // width: 150px;
      }
      .percent {
        font-size: 12px;
        color: #FFFFFF;
      }
    }
  }
}



// global style's
.ant-spin {
	.ant-spin-dot-item {
		background-color: #c1fc49;
	}
}
.ant-select-item-option-active {
	background: #303244 !important;
}
.ant-select-item-option-content {
	color: #FFFFFF !important;
}
.ant-select-item-empty {
	color: #FFFFFF !important;
}
.ant-input-number-group-addon {
	border: none !important;
	color: unset;
}

.ant-input {
	border-radius: 32px;
	border: 1px solid #40435b;
	background: transparent;
	color: #FFFFFF;
	height: 30px;
	&::placeholder {
		color: #FFFFFF;
	}
	&:focus {
		border-color: #c1fc49;
	}
	&[aria-required="true"] {
		background: #303244;
	}
}

.search-bar {
	.ant-input-outlined {
		border-radius: 32px;
		border: 1px solid #40435b;
		background: #20222E !important;
		color: #FFFFFF;
		// height: 30px;
		&::placeholder {
			color: #FFFFFF;
		}
		&:focus {
			border-color: #c1fc49;
		}
		&[aria-required="true"] {
			background: #303244;
		}
	}
}
.ant-input-number,
.ant-input-number-wrapper {
	border-radius: 32px;
	border: 1px solid #40435b;
	background: #303244 !important;
	color: #FFFFFF;
	// height: 45px;
	&:focus {
		border-color: #c1fc49;
	}
	&[aria-required="true"] {
		background: #303244;
	}
	&-input {
		color: #FFFFFF !important;
		margin-top: 7px;
		&::placeholder {
			color: #FFFFFF !important;
		}
		padding: 11px !important;
	}
	&:hover {
		background-color: #40435a !important;
	}
}

.ant-checkbox-wrapper {
	// position: absolute;
	// right: 0;
	// top: 0;
	label {
		span {
			color: #ffffff !important;
		}
	}
	.ant-checkbox-inner {
		background-color: #66697c;
		border: none;
	}
	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: #c1fc49;
		border-color: #c1fc49;
		&::after {
			border-color: #303244;
		}
		&:hover {
			background-color: #c1fc49;
		}
	}
}
.ant-modal-mask {
	background-color: rgba(0, 0, 0, 0.20) !important;
}
