.App {
  text-align: center;
}

#root {
  background-color: #20222E;
}

body {
  background-color: #20222E;
}

.ant-table {
    &-content {
      background-color: #20222E;
      .ant-table-thead {
        th {
          background-color: #303244;
          color: #FFFFFF;
          font-family: Poppins;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 1.1px;
          text-transform: uppercase;
          border: none;
          padding: 22px;
          &::before {
            background-color: transparent !important;
          }
          &:first-child {
            border-bottom-left-radius: 8px;
          }
          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
        td {
          background-color: #303244;
          border-bottom-right-radius: 8px;
          border: none;
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          background-color: #20222E;
          .ant-table-column-sort{
            background-color: #20222E;
          }
          .ant-table-cell-row-hover {
            background-color: #303244;
          }
        }
      }
      .ant-table-placeholder {
        background-color: transparent !important;
        .ant-table-cell {
          border-bottom: none;
          border: 1px solid #FFFFFF;
          border-radius: 10px;
          .ant-empty-description {
            color: #FFFFFF;
          }
        }
        :hover {
          background: #303244 !important;
        }
      }
    }
}

.ant-pagination {
  margin-top: 25px;
  text-align: center;
  &-item {
    color: #C1FC49;
    &-active {
      background-color: #C1FC49;
      color: #20222E;
      border: none;
    }
  }
  .anticon-left {
    color: #C1FC49;
  }
  .anticon-right {
    color: #C1FC49;
  }
  .ant-pagination-disabled {
    .anticon {
      color: gray;
    }
  }
  .ant-pagination-item-ellipsis {
    color: #C1FC49 !important;
  }
  &-options {
    .ant-select {
      .ant-select-selector {
        background-color: transparent !important;
        border: 1px solid #C1FC49 !important;
        color: #C1FC49 !important;
      }
      .ant-select-arrow {
        color: #C1FC49 !important;
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ant-empty-description {
 color: #C1FC49 !important;
 font-weight: bold; 
}
.ant-select-item-option {
  &:hover {
    background-color: #1f212e !important;
  }
}

.ant-select-item-option-selected {
  background-color: #1f212e !important;
}

.ant-input {
  background-color: transparent !important;
}

.ant-select-selector {
  background-color: transparent !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: transparent;
}

body::-webkit-scrollbar
{
	width: 8px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #c1fc49;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #40435a !important;
}

.ant-input-number-outlined:focus {
  background-color: transparent !important;
}

// .ant-input {
//   background-color: #303244 !important;
// }

.input:-internal-autofill-selected {
  background-color: transparent !important;
}

.ant-form-item-explain-error {
  font-size: 12px !important;
}

.ant-popover {
  max-width: 30%;
  &-content {
    .ant-popover-inner {
      background-color: #303244 !important;
      overflow: scroll;
      .ant-popover-title {
        color: #FFFFFF;
      }
      .ant-popover-inner-content {
        color: #FFFFFF;
      }
    }
  }
  &-arrow {
    --antd-arrow-background-color: #303244 !important;
  }
}

// .ant-notification {
//   border-radius: 8px;
//   .ant-notification-notice-info {
//     border-radius: 8px;
//     background-color: #303244 !important;
//     .ant-notification-notice-message {
//       color: #FFFFFF !important;
//     }
//   }
// }


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
