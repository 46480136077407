.side-nav {
  border-radius: 8px;
  background: #101117;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 760px;
  max-width: 290px;
  .profile-pic-cont {
    display: flex;
    margin-top: 32px;
    border-bottom: 1px solid #40435B;
    position: relative;
    .info-inner {
      overflow: hidden;
      span {
        color: #C1FC49;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      h2 {
        margin-bottom: 0;
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .ant-avatar {
      margin: 0 10px 16px 10px;
      flex-shrink: 0; 
    }
    .not_connected {
      top: 0;
      .ant-avatar-string {
        left: 30%;
      }
    }
    .anticon-arrow-left {
      font-size: 20px;
      color: #C1FC49;
      align-items: flex-start;
      margin-top: 10px;
    }
  }
  .ant-menu {
    background: transparent;
    margin-top: 32px;
    &-item {
      padding: 23px;
      color: #FFFFFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      &-active {
        background-color: #20222E !important;
        color: #FFFFFF !important;
        .ant-menu-item-icon {
          color: #C1FC49;
        }
      }
      &-selected {
        background-color: #20222E;
        color: #C1FC49;
      }
      &-disabled {
        color: #575757 !important;
      }
      .ant-badge {
        // sup {
        //   min-width: 12px;
        //   height: 12px;
        //   line-height: 0.8;
        //   bdi {
        //     line-height: 0.5;
        //     font-size: 8px;
        //   }
        // }
        // .ant-badge-count {
        //   box-shadow: none;
        // }
        .ant-badge-dot {
          box-shadow: none;
        }
      }
    }
  }
  
  .info-wrapper {
    position: absolute;
    bottom: 45px;
    width: 85%;
    .ant-list {
      .ant-btn {
        padding: inherit;
        height: 52px;
        width: 80%;
        background-color: #20222E;
        border: none;
        color: #FFFFFF;
        .anticon-logout {
          color: #ff4d4f;
        }
        &:disabled {
					// background: #40435b;
					color: #66697c;
          // .ant-btn-icon {
          //   color: #66697c !important;
          // }
				}
      }
    }
    .poweredby {
      margin-top: 10px;
      p {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}