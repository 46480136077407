.reg-header {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-items: flex-end;
  
  .ant-btn {
    border-radius: 32px;
    background: transparent;
    // width: 176px;
    height: 40px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    font-weight: 600;
  }
}

@media screen and (max-width:724px) {
  .reg-header {
    align-items: center;
    padding: 5px;
  }
}