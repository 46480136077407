.asset-preview {
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 930px;
  .img-height {
    max-height: 600px;
  }
  audio {
    width: 100% !important;
  }
  #header-bar {
    display: none !important;
  }
}