.header-left {
  display: flex;
  align-items: center;
  .status-btn {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 32px;
    background: #303244;
    border: none;
    // padding: 16px 24px;
    height: 50px;
    margin-left: 15px;
    padding: 0 22px 0 15px;
    .connected {
      color: #131907;
      text-align: center;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      border-radius: 16px;
      background: #C1FC49;
      padding: 4px 8px;
    }
    .meta {
      color: #FFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      margin-left: 8px;
    }
  }
  .active {
    border: 1px solid #545b43;
    box-shadow: 0 2px 15px rgba(193, 252, 73, 0.4);
  }
  .logo {
    .ant-image {
      margin-right: 22px;
    }
  }
}