 .header-dropdown {
    width: 252px !important;
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        &:first-child {
          border-bottom: 1px solid #40435B !important;
          border-radius: 0 !important;
        }
      }
      .ant-dropdown-menu-item-disabled {
        background-color: transparent !important;
      }
    }
} 
.ant-dropdown {
  &-arrow {
    &::before {
      background: #303244 !important;
    }
  }
  &-menu {
    background: #303244 !important;
    padding: 6px 0px !important;
    &-item {
      color: #ffffff !important;
      padding: 14px !important;
    }
  }
}